<div class="card">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <div class="card-body text-center">
          <h5 class="card-title m-b-0">Notifications</h5>
        </div>

        <div *ngIf="noData" class="noData">
          <img src="../../assets/Animation - 1712133187330.gif" alt="">
          <h3 class="text-danger text-center"></h3>
        </div>
        <!-- <div class="table-responsive" *ngIf="!noData">
          <div class="table-container">
            <table class="table text-center">
              <thead class="thead-light">
                <tr class="head_align">
                  <th scope="col">Order ID</th>
                  <th scope="col">Status</th>
                  <th scope="col">Description</th>
                  <th scope="col">Date & Time</th>

                </tr>
              </thead>
              <tbody class="customtable">
                <tr *ngFor="let not of allnoti | paginate: { itemsPerPage: 10, currentPage: page }">
                  <td>{{ getDisplayValue(not) }}</td>
                  <td>{{ not?.status }}</td>
                  <td>{{ not?.message }}</td>
                  <td>{{not?.created_at |date:'medium'}}</td>
                </tr>
              </tbody>
            </table>
            <p class="mb-0 text-center"><pagination-controls (pageChange)="page = $event"></pagination-controls></p>
          </div>
        </div> -->

        <div class="row" *ngIf="!noData">
          <div class="col-md-12 notification"
            *ngFor="let not of allnoti | paginate: { itemsPerPage: 20, currentPage: page }">
            <div class="row">
              <div class="col-md-3">
                <p>
                  <b>
                    <span *ngIf="not?.order_id; else showOrderType">Order ID: {{ not?.order_id }}</span>
                    <ng-template #showOrderType>Type: {{ not?.orderInfo }}</ng-template>
                  </b>
                </p>
              </div>
              <div class="col-md-4">
                <p class="status">Status: {{ not?.status }}</p>
              </div>
              <div class="col-md-5">
                <p class="date-time">Date & Time: {{ not?.created_at |date:'medium'}}</p>
              </div>
              <div class="col-md-12">
                <p class="description">Description: {{ not?.message }}</p>
              </div>
            </div>
          </div>


          <!-- <p class="mb-0 text-center"><pagination-controls (pageChange)="page = $event"></pagination-controls></p> -->
        </div>



        

        <!-- <div class="table-responsive">
          <div class="table-container">
            <table class="table1">


              <tbody class="customtable1" *ngFor="let not of allnoti">
                <tr>
                  <td>ID</td>
                  <td>:</td>
                  <td>{{ not?.order_id }}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>:</td>
                  <td>{{ not?.status }}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>:</td>
                  <td>{{ not?.message }}</td>

                </tr>
                <tr>
                  <td>Date & Time</td>
                  <td>:</td>
                  <td>{{not?.created_at |date:'medium'}}</td>

                </tr>
              </tbody>



            </table>

          </div>
        </div> -->

      </div>
    </div>
  </div>

</div>